/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Checkbox from '../../../../components/ui/Checkbox/Checkbox'
import linkedinIcon from '../../../../assets/images/onboardingDashboard/ads/linkedinIcon.png'
import googleIcon from '../../../../assets/images/onboardingDashboard/ads/googleIcon.png'
import youTubeIcon from '../../../../assets/images/onboardingDashboard/ads/youTubeIcon.png'
import ToggleSwitch from '../../../../components//ui/SwitchController'
import axiosInstance from '../../../../axiosConfig'
import {
  adAccountContainer,
  adAccountContainerHeading,
  contentContainerStyle,
  actionButtonContainer,
  actionButton,
  toggleStyle,
  overlayStyle
} from './style'

const ICON_MAPPER = {
  linkedin: linkedinIcon,
  google: googleIcon,
  youtube: youTubeIcon
}

export const AdManagerModal = ({ socialAccounts, onClose = () => {} }) => {
  const { accounts, connect } = socialAccounts || {}
  const [showSuccessOption, setShowSuccessOption] = useState(null)
  const [selectedUserAdAccount, setSelectedUserAdAccount] = useState([])
  const [isLoading, setIsLoading] = useState(null)

  const handleConnect = async () => {
    setIsLoading(true)
    try {
      const accountIds = selectedUserAdAccount.map(({ id }) => `${id}`)
      let url = ''
      let data = {}
      switch (connect) {
        case 'google':
          url = 'google_ads/selected_account'
          data = {
            google_ad: {
              account_id: accountIds
            }
          }
          break
        case 'linkedin':
          url = 'linked_in/selected_account'
          data = {
            linkedin: {
              account_id: accountIds
            }
          }
          break
      }
      const { status } = await axiosInstance.post(url, data)
      if (status === 200) {
        setShowSuccessOption(true)
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)

      console.log(err)
    }
  }
  const iconSource = ICON_MAPPER[connect.toLowerCase()]
  const modalContent = (
    <div css={overlayStyle}>
      {!showSuccessOption && accounts && accounts.length && (
        <div css={adAccountContainer}>
          <div css={adAccountContainerHeading}>
            <div
              style={{
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid #ccc',
                borderRadius: '4px',
                marginRight: 20,
                background: '#fff'
              }}
            >
              <img
                src={iconSource}
                alt={connect.toLowerCase()}
                style={{ objectFit: 'contain' }}
              />
            </div>
            Pick your Ad Accounts
          </div>
          <div css={contentContainerStyle}>
            <div className='tableHeading'>
              <p className='heading-item'>Ad Account</p>
              <p className='heading-item'>Ad Account Id</p>
              <p className='heading-item'>Auto Tracking</p>
            </div>
            {accounts.map(({ id, name }) => (
              <div key={id} className='accountRow'>
                <p className='account-items'>
                  <Checkbox
                    index={id}
                    onChange={event => {
                      if (event.target.checked) {
                        const updatedIds = [
                          ...selectedUserAdAccount,
                          { id, auto_tracking: true }
                        ]
                        setSelectedUserAdAccount(updatedIds)
                      } else {
                        const accountIds = selectedUserAdAccount.filter(
                          ({ id: adAccountId }) => adAccountId !== id
                        )
                        setSelectedUserAdAccount(accountIds)
                      }
                    }}
                    checked={selectedUserAdAccount.some(
                      ({ id: adAccountId }) => adAccountId === id
                    )}
                  />
                  {id}
                </p>
                <p className='account-items'>{name}</p>
                <ToggleSwitch
                  showPause={false}
                  toggleSwitchState={state => {
                    const itemIndex = selectedUserAdAccount.findIndex(
                      ({ id: accountId }) => id == accountId
                    )
                    const clonedItems = JSON.parse(
                      JSON.stringify(selectedUserAdAccount)
                    )
                    if (itemIndex !== -1) {
                      clonedItems.splice(itemIndex, 1, {
                        id,
                        auto_tracking: state == 'active' ? true : false
                      })
                    }
                    setSelectedUserAdAccount(clonedItems)
                  }}
                  state={
                    (
                      selectedUserAdAccount.find(
                        ({ id: adAccountId }) => id == adAccountId
                      ) || {}
                    ).auto_tracking
                      ? 'active'
                      : 'stop'
                  }
                  containerStyle={toggleStyle}
                  style={{
                    position: 'relative',
                    top: 'unset',
                    width: '34px'
                  }}
                />
              </div>
            ))}
          </div>
          <div css={actionButtonContainer}>
            <button
              css={actionButton(isLoading)}
              onClick={() => onClose(false)}
            >
              Back
            </button>
            <button
              className='logInButton connect-button'
              onClick={() => handleConnect()}
              css={actionButton(isLoading)}
            >
              Connect
              {isLoading && <div className='loader' />}
            </button>
          </div>
        </div>
      )}
      {showSuccessOption && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '615px',
            height: '400px',
            background: '#18a3e2',
            borderRadius: '8px'
          }}
        >
          <div
            css={adAccountContainerHeading}
            style={{
              color: '#fff',
              flexDirection: 'column',
              justifyContent: ' space-between',
              flex: '1',
              maxHeight: '200px'
            }}
          >
            <div
              style={{
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid #ccc',
                borderRadius: '4px',
                marginRight: 20,
                background: '#fff'
              }}
            >
              <img src={iconSource} style={{ objectFit: 'contain' }} />
            </div>
            Your Ad Accounts are successfully connected
          </div>
          <div
            css={actionButtonContainer}
            style={{ padding: 0, marginBottom: 20 }}
          >
            <button
              className='logInButton'
              style={{
                width: 164,
                color: '#333',
                border: '1px solid #CCCCCC',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 0 -2px 2px rgba(102,153,204,0.4)'
              }}
              css={actionButton(isLoading)}
              onClick={() => {
                onClose(false)
                setShowSuccessOption(false)
              }}
            >
              Let's keep going
            </button>
          </div>
        </div>
      )}
    </div>
  )
  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('modal-root')
  )
}

export default AdManagerModal
