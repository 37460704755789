/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Icon from '@material-ui/core/Icon'

import { addAudienceContainer } from './style'

const AddAd = () => {
  return (
    <div css={addAudienceContainer}>
      <Icon className='plusIcon'>add</Icon>
    </div>
  )
}

export default AddAd
