/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../axiosConfig'

const OAuthCallback = () => {
  const navigate = useNavigate()
  const search = useLocation().search
  useEffect(() => {
    const fetchToken = async () => {
      const urlParams = new URLSearchParams(search)
      const code = urlParams.get('code')
      const state = urlParams.get('state')

      if (!code) {
        console.error('Authorization code not found')
        return
      }
      try {
        handleBackendConnect(code, state)
      } catch (err) {
        console.error('Error during token request', err)
      }
    }

    const handleBackendConnect = async (code, state) => {
      try {
        let data = {}
        let url = ''
        const [connect, page] = state.split('_')
        switch (connect) {
          case 'google':
            data = {
              google_ad: {
                token: code
              }
            }
            url = '/google_ads/accounts'
            break
          case 'linkedin':
            data = {
              linkedin: {
                token: code
              }
            }
            url = 'linked_in/accounts'
            break
          default:
            return
        }
        const resp = await axiosInstance.post(url, data)
        const { status: beCallStatus, data: { accounts = [] } = {} } = resp
        console.log(resp)
        if (beCallStatus === 200) {
          if (window.opener) {
            window.opener.postMessage(
              {
                success: true,
                accounts,
                connect,
                page
              },
              '*'
            )
            window.close() // Close the current tab after notifying the original one
          }
        } else {
          console.error('Failed to connect to backend')
        }
      } catch (err) {
        console.error('Error during backend call', err)
      }
    }

    fetchToken()
  }, [search, navigate])

  return <div>Redirecting</div>
}

export default OAuthCallback
