/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'

import Checkbox from '../../../../ui/Checkbox/Checkbox'
import Dropdown from '../../../../ui/Dropdown/Dropdown'

import { channelSourceList } from '../../../../../constant/ads'

import {
  content,
  campaignContainer,
  adContainer,
  previewContainer,
  inputStyle,
  inputWithCurrencyStyle,
  channelContainer,
  label,
  channelLabel,
  spreadAcrossChannelOption,
  dateContainer,
  adNameContainer,
  textContainer,
  headlineContainer,
  newsfeedDescriptionContainer,
  displayLinkContainer,
  callToActionDropdownStyle,
  previewDropdownContainer,
  previewDropdownStyle,
  previewDesktopDropdownStyle,
  channel,
  blank,
  charCountError,
  charCountStyle,
  errorBorderStyle,
  borderStyle,
  errorMessageStyle
} from './style'

const CharacterLimitInput = ({
  maxChars,
  type = 'text',
  fieldName = '',
  value = '',
  onChange,
  validityFields
}) => {
  const [charCount, setCharCount] = useState(maxChars)

  useEffect(() => {
    setCharCount(maxChars - value.length)
  }, [value])

  return (
    <div>
      <input
        type={type}
        data-isInvalid={validityFields.includes(fieldName)}
        value={value}
        css={charCount < 0 ? errorBorderStyle : borderStyle}
        className='textboxStyle'
        onChange={onChange}
      />
      <div css={charCount < 0 ? charCountError : charCountStyle}>
        {charCount >= 0
          ? `${charCount} characters remaining`
          : `Character limit exceeded by ${-charCount}`}
      </div>
    </div>
  )
}

const CharacterLimitTextArea = ({
  maxChars,
  type = 'text',
  fieldName = '',
  value = '',
  onChange,
  validityFields
}) => {
  const [charCount, setCharCount] = useState(maxChars)

  useEffect(() => {
    setCharCount(maxChars - value.length)
  }, [value])

  return (
    <div>
      <textarea
        type={type}
        data-isInvalid={validityFields.includes(fieldName)}
        value={value}
        css={charCount < 0 ? errorBorderStyle : borderStyle}
        className='textboxStyle'
        onChange={onChange}
      />
      <div css={charCount < 0 ? charCountError : charCountStyle}>
        {charCount >= 0
          ? `${charCount} characters remaining`
          : `Character limit exceeded by ${-charCount}`}
      </div>
    </div>
  )
}

const getChannelSource = channel => {
  let channelSource = channelSourceList.find(
    channelSource => channelSource.key === channel
  )
  return (
    <img
      className='iconStyle'
      alt={channelSource.alt}
      src={channelSource.src}
    ></img>
  )
}

const AdDetails = ({
  showAdCampaignDetails,
  selectedChannels,
  campaignDetails,
  setCampaignDetails = () => {},
  validityFields = []
}) => {
  const channelBudgetBreakdown = selectedChannels.filter(channel => channel)
  const [invalidUrl, setInvalidUrl] = useState(false)

  const onCheckboxClickHandler = event => {
    const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
    const channelBudget = campaignDetails.budget / channelBudgetBreakdown.length
    channelBudgetBreakdown.forEach(channel => {
      prevCampaignDetails[`${channel}Budget`] = channelBudget
    })
    setCampaignDetails({
      ...prevCampaignDetails,
      spreadBudgetAcrossChannels: event.target.checked
    })
  }

  const handleOnChange = (key, value) => {
    const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
    setCampaignDetails({ ...prevCampaignDetails, [key]: value })
  }

  const validateURL = value => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        "((([a-zA-Z0-9$-_@.&+!*'(),]|%[0-9a-fA-F]{2})+)(:([a-zA-Z0-9$-_@.&+!*'(),]|%[0-9a-fA-F]{2})+)?@)?" + // user:password@
        '((\\[(|v[0-9a-fA-F]+)\\]?)|(([a-zA-Z0-9-]+\\.)+([a-zA-Z]{2,})))' + // host
        '(:\\d{2,5})?' + // port
        '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path
        '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
        '(#[-a-zA-Z0-9_]*)?$'
    )
    return urlPattern.test(value)
  }

  return (
    <div css={content(showAdCampaignDetails)}>
      <div css={campaignContainer}>
        <div className='budgetContainer'>
          <label css={label}>Budget</label>
          <div css={inputWithCurrencyStyle}>
            <span className='currency'>$</span>
            <input
              css={inputStyle}
              className='inputText'
              type='number'
              value={campaignDetails.budget}
              onChange={event =>
                setCampaignDetails({
                  ...campaignDetails,
                  budget: event.target.value
                })
              }
            />
          </div>
        </div>
        {channelBudgetBreakdown.length > 0 && (
          <div css={spreadAcrossChannelOption}>
            <Checkbox
              checked={campaignDetails.spreadBudgetAcrossChannels}
              onChange={e => onCheckboxClickHandler(e)}
            />
            <label className='labelText'>Spread evenly across channels</label>
          </div>
        )}
        <div css={channelContainer}>
          {channelBudgetBreakdown.map(channelName => {
            const budgetName = `${channelName}Budget`
            return (
              <div css={channel} key={channelName}>
                {getChannelSource(channelName)}
                <label css={channelLabel}>
                  {channelName[0].toUpperCase() +
                    channelName.slice(1).toLowerCase()}
                </label>
                <div css={inputWithCurrencyStyle}>
                  <span className='currency'>$</span>
                  <input
                    css={inputStyle}
                    className='inputText'
                    value={campaignDetails[budgetName]}
                    onChange={({ target: { value } = {} }) =>
                      setCampaignDetails({
                        ...campaignDetails,
                        [budgetName]: value
                      })
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div css={dateContainer}>
          <div className='startDate'>
            <div>Start Date</div>
            <input
              css={inputStyle}
              type='date'
              value={campaignDetails.startDate}
              onChange={({ target: { value } = {} }) =>
                setCampaignDetails({
                  ...campaignDetails,
                  startDate: value
                })
              }
            />
          </div>
          <div className='endDate'>
            <div>End Date</div>
            <input
              css={inputStyle}
              type='date'
              value={campaignDetails.endDate}
              onChange={({ target: { value } = {} }) =>
                setCampaignDetails({
                  ...campaignDetails,
                  endDate: value
                })
              }
            />
          </div>
        </div>
      </div>

      <div css={adContainer}>
        <label className='requiredLabel'>* Required</label>

        <div css={textContainer}>
          <label>Text*</label>
          <CharacterLimitInput
            maxChars={50}
            type='text'
            fieldName='text'
            validityFields={validityFields}
            value={campaignDetails.text}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('text', value)
            }
          />
        </div>
        <div css={headlineContainer}>
          <label>Headline*</label>
          <CharacterLimitInput
            maxChars={50}
            type='text'
            fieldName='headline'
            validityFields={validityFields}
            value={campaignDetails.headline}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('headline', value)
            }
          />
        </div>
        <div css={newsfeedDescriptionContainer}>
          <label>Newsfeed Link Description*</label>
          <CharacterLimitTextArea
            maxChars={50}
            type='text'
            fieldName='newsFeedLinkDescription'
            validityFields={validityFields}
            value={campaignDetails.newsFeedLinkDescription}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('newsFeedLinkDescription', value)
            }
          />
        </div>
        <div css={displayLinkContainer}>
          <label>Display Link</label>
          <div>
            <input
              className='textboxStyle'
              css={invalidUrl && errorBorderStyle}
              value={campaignDetails.displayLink}
              onChange={({ target: { value } = {} }) => {
                const isValidURL = validateURL(value)
                if (isValidURL || value === '') {
                  setInvalidUrl(false)
                } else {
                  setInvalidUrl(true)
                }
                handleOnChange('displayLink', value)
              }}
            />
            <div css={errorMessageStyle}>
              {invalidUrl ? 'Please enter valid url' : null}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div>
            <label>Objective</label>
            <div>
              <Dropdown
                value={
                  (campaignDetails.objective &&
                    campaignDetails.objective === 'OUTCOME_AWARENESS' &&
                    'REACH') ||
                  (campaignDetails.objective &&
                    campaignDetails.objective === 'OUTCOME_LEADS' &&
                    'LEAD_GENERATION') ||
                  ''
                }
                style={callToActionDropdownStyle}
                options={['REACH', 'LEAD_GENERATION']}
                convertToTitleCase
                onOptionSelected={value => {
                  if (value === 'REACH') {
                    handleOnChange('objective', 'OUTCOME_AWARENESS')
                  } else {
                    handleOnChange('objective', 'OUTCOME_LEADS')
                  }
                }}
              ></Dropdown>
            </div>
          </div>
          <div>
            <label>Call to Action</label>
            <div>
              <Dropdown
                value={campaignDetails.callToAction}
                style={callToActionDropdownStyle}
                convertToTitleCase
                options={[
                  'BOOK_TRAVEL',
                  'CONTACT_US',
                  'DONATE',
                  'DONATE_NOW',
                  'DOWNLOAD',
                  'GET_DIRECTIONS',
                  'GO_LIVE',
                  'INTERESTED',
                  'LEARN_MORE',
                  'LIKE_PAGE',
                  'MESSAGE_PAGE',
                  'RAISE_MONEY',
                  'SAVE',
                  'SEND_TIP',
                  'SHOP_NOW',
                  'SIGN_UP',
                  'VIEW_INSTAGRAM_PROFILE',
                  'INSTAGRAM_MESSAGE',
                  'LOYALTY_LEARN_MORE',
                  'PURCHASE_GIFT_CARDS',
                  'PAY_TO_ACCESS',
                  'SEE_MORE',
                  'TRY_IN_CAMERA',
                  'WHATSAPP_LINK',
                  'BOOK_NOW',
                  'CHECK_AVAILABILITY',
                  'ORDER_NOW',
                  'WHATSAPP_MESSAGE',
                  'GET_MOBILE_APP',
                  'INSTALL_MOBILE_APP',
                  'USE_MOBILE_APP',
                  'INSTALL_APP',
                  'USE_APP',
                  'PLAY_GAME',
                  'WATCH_VIDEO',
                  'WATCH_MORE',
                  'OPEN_LINK',
                  'NO_BUTTON',
                  'LISTEN_MUSIC',
                  'MOBILE_DOWNLOAD',
                  'GET_OFFER',
                  'GET_OFFER_VIEW',
                  'BUY_NOW',
                  'BUY_TICKETS',
                  'UPDATE_APP',
                  'BET_NOW',
                  'ADD_TO_CART',
                  'SELL_NOW',
                  'GET_SHOWTIMES',
                  'LISTEN_NOW',
                  'GET_EVENT_TICKETS',
                  'REMIND_ME',
                  'SEARCH_MORE',
                  'PRE_REGISTER',
                  'SWIPE_UP_PRODUCT',
                  'SWIPE_UP_SHOP',
                  'PLAY_GAME_ON_FACEBOOK',
                  'VISIT_WORLD',
                  'OPEN_INSTANT_APP',
                  'JOIN_GROUP',
                  'GET_PROMOTIONS',
                  'SEND_UPDATES',
                  'INQUIRE_NOW',
                  'VISIT_PROFILE',
                  'CHAT_ON_WHATSAPP',
                  'EXPLORE_MORE',
                  'CONFIRM',
                  'JOIN_CHANNEL',
                  'MAKE_AN_APPOINTMENT',
                  'ASK_ABOUT_SERVICES',
                  'BOOK_A_CONSULTATION',
                  'GET_A_QUOTE',
                  'BUY_VIA_MESSAGE',
                  'ASK_FOR_MORE_INFO',
                  'CHAT_WITH_US',
                  'VIEW_PRODUCT',
                  'VIEW_CHANNEL',
                  'CALL',
                  'MISSED_CALL',
                  'CALL_NOW',
                  'CALL_ME',
                  'APPLY_NOW',
                  'BUY',
                  'GET_QUOTE',
                  'SUBSCRIBE',
                  'RECORD_NOW',
                  'VOTE_NOW',
                  'GIVE_FREE_RIDES',
                  'REGISTER_NOW',
                  'OPEN_MESSENGER_EXT',
                  'EVENT_RSVP',
                  'CIVIC_ACTION',
                  'SEND_INVITES',
                  'REFER_FRIENDS',
                  'REQUEST_TIME',
                  'SEE_MENU',
                  'SEARCH',
                  'TRY_IT',
                  'TRY_ON',
                  'LINK_CARD',
                  'DIAL_CODE',
                  'FIND_YOUR_GROUPS'
                ]}
                onOptionSelected={value =>
                  handleOnChange('callToAction', value)
                }
              ></Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div css={previewContainer}>
        <label>Preview</label>
        <div css={previewDropdownContainer}>
          <Dropdown
            value={'Facebook'}
            options={['Facebook', 'Instagram', 'Linkedin']}
            style={previewDropdownStyle}
          />
          <Dropdown
            value={'Single Image'}
            options={['Facebook', 'Instagram', 'Linkedin']}
            style={previewDropdownStyle}
          />
          <Dropdown
            value={'Newsfeed'}
            options={['Facebook', 'Instagram', 'Linkedin']}
            style={previewDesktopDropdownStyle}
          />
        </div>
        <div css={blank}></div>
      </div>
    </div>
  )
}

export default AdDetails
