/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'

import SideDrawer from '../SideDrawer'

import {
  socialAndAnalyticsSection,
  socialAndAnalyticsContent,
  emailOptions,
  emailOptionBox,
  analyticsOptions,
  analyticsOptionBox,
  adsOptions,
  adsOptionBox,
  solutionNotExistingContainer,
  addSolutionContainer,
  addSolutionOptionContainer,
  addSolutionOption,
  allSetButton,
  solutionNotListedButton,
  addSolutionButton,
  inputStyle,
  overlayStyle
} from './styles'

import facebookIcon from '../../../assets/images/onboardingDashboard/ads/facebookIcon.png'
import instagramIcon from '../../../assets/images/onboardingDashboard/ads/instagramIcon.png'
import linkedinIcon from '../../../assets/images/onboardingDashboard/ads/linkedinIcon.png'
import twitterIcon from '../../../assets/images/onboardingDashboard/ads/twitterIcon.png'
import theTradeDeskIcon from '../../../assets/images/onboardingDashboard/ads/theTradeDeskIcon.png'
import googleIcon from '../../../assets/images/onboardingDashboard/ads/googleIcon.png'
import youTubeIcon from '../../../assets/images/onboardingDashboard/ads/youTubeIcon.png'

import mailchimpIcon from '../../../assets/images/onboardingDashboard/email/mailchimpIcon.png'
import constantContactIcon from '../../../assets/images/onboardingDashboard/email/constantContactIcon.png'
import salesforceIcon from '../../../assets/images/onboardingDashboard/email/salesforceIcon.png'
import campaignMonitorIcon from '../../../assets/images/onboardingDashboard/email/campaignMonitorIcon.png'
import iContactIcon from '../../../assets/images/onboardingDashboard/email/iContactIcon.png'
import zohoCampaignsIcon from '../../../assets/images/onboardingDashboard/email/zohoCampaignsIcon.png'

import googleAnalyticsIcon from '../../../assets/images/onboardingDashboard/analytics/googleAnalyticsIcon.png'
import adobeAnalyticsIcon from '../../../assets/images/onboardingDashboard/analytics/adobeAnalyticsIcon.png'
import tableauIcon from '../../../assets/images/onboardingDashboard/analytics/tableauIcon.png'
import microsoftPowerBiDesktopIcon from '../../../assets/images/onboardingDashboard/analytics/microsoftPowerBiDesktopIcon.png'
import amplitudeIcon from '../../../assets/images/onboardingDashboard/analytics/amplitudeIcon.png'
import pendoIcon from '../../../assets/images/onboardingDashboard/analytics/pendoIcon.png'
import sapIcon from '../../../assets/images/onboardingDashboard/analytics/sapIcon.png'
import lookerIcon from '../../../assets/images/onboardingDashboard/analytics/lookerIcon.png'
import qlikIcon from '../../../assets/images/onboardingDashboard/analytics/qlikIcon.png'
import semRushIcon from '../../../assets/images/onboardingDashboard/analytics/semRushIcon.png'
import microStrategyIcon from '../../../assets/images/onboardingDashboard/analytics/microStrategyIcon.png'
import domoIcon from '../../../assets/images/onboardingDashboard/analytics/domoIcon.png'
import FbAdAccountPopUp from '../../../common/components/fbAdAccountPopup'
import AdManagerModal from './AdManagerModal'

import { updateUserFbAdAccounts } from '../../../store/onboardingDashboard'
import { useDispatch, useSelector } from 'react-redux'

const adsOptionsData = [
  [
    {
      id: 1,
      name: 'Facebook',
      source: facebookIcon
    },
    {
      id: 2,
      name: 'Instagram',
      source: instagramIcon
    }
  ],
  {
    id: 3,
    name: 'LinkedIn',
    source: linkedinIcon
  },
  {
    id: 4,
    name: 'Twitter',
    source: twitterIcon
  },
  {
    id: 5,
    name: 'The Trade Desk',
    source: theTradeDeskIcon
  },
  [
    {
      id: 6,
      name: 'Google',
      source: googleIcon
    },
    {
      id: 7,
      name: 'Youtube',
      source: youTubeIcon
    }
  ]
]

const IconMapper = {
  Facebook: facebookIcon
}

const emailOptionsData = [
  {
    id: 1,
    name: 'Mailchimp',
    source: mailchimpIcon
  },
  {
    id: 2,
    name: 'Constant Contact',
    source: constantContactIcon
  },
  {
    id: 3,
    name: 'Salesforce',
    source: salesforceIcon
  },
  {
    id: 4,
    name: 'Campaign Monitor',
    source: campaignMonitorIcon
  },
  {
    id: 5,
    name: 'iContact',
    source: iContactIcon
  },
  {
    id: 6,
    name: 'Zoho Campaigns',
    source: zohoCampaignsIcon
  }
]

const analyticsOptionsData = [
  {
    id: 1,
    name: 'Google Analytics',
    source: googleAnalyticsIcon
  },
  {
    id: 2,
    name: 'Adobe Analytics',
    source: adobeAnalyticsIcon
  },
  {
    id: 3,
    name: 'Tableau',
    source: tableauIcon
  },
  {
    id: 4,
    name: 'Microsoft Power BI Desktop',
    source: microsoftPowerBiDesktopIcon
  },
  {
    id: 5,
    name: 'Amplitude',
    source: amplitudeIcon
  },
  {
    id: 6,
    name: 'Pendo',
    source: pendoIcon
  },
  {
    id: 7,
    name: 'SAP',
    source: sapIcon
  },
  {
    id: 8,
    name: 'Looker',
    source: lookerIcon
  },
  {
    id: 9,
    name: 'Qlik',
    source: qlikIcon
  },
  {
    id: 10,
    name: 'SemRush',
    source: semRushIcon
  },
  {
    id: 11,
    name: 'MicroStrategy',
    source: microStrategyIcon
  },
  {
    id: 12,
    name: 'Domo',
    source: domoIcon
  }
]

const newSolutionsOptions = [
  {
    id: 1,
    status: true,
    description: '',
    isEditable: true
  },
  {
    id: 2,
    status: false,
    isEditable: true,
    description: ''
  }
]

const fb_permissions = [
  'public_profile',
  'email',
  'business_management',
  'ads_management',
  'leads_retrieval',
  'pages_read_engagement',
  'pages_manage_metadata',
  'pages_read_user_content',
  'pages_show_list',
  'pages_manage_ads'
]

const SocialAndAnalyticsSection = React.forwardRef(
  (
    {
      selectedSection,
      scrollHandler = () => {},
      setSelectedSection = () => {},
      socialAccounts
    },
    ref
  ) => {
    const state = useSelector(state => state)
    const [solutionExisting, setSolutionExisting] = useState(true)
    const [newSolutionsData, setNewSolutionsData] =
      useState(newSolutionsOptions)
    const [adAccounts, setAdAccounts] = useState(null)
    const [modalViewFlag, setModalViewFlag] = useState(false)
    const [connectingSource, setConnectingSource] = useState(null)
    const [selectedUserAdAccounts, setSelectedUserAdAccounts] = useState([])
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [showSuccessOption, setShowSuccessOption] = useState(false)
    const [facebookAccessToken, setFacebookAccessToken] = useState(null)
    // const [googleClient, setGoogleClient] = useState(null)
    const [showAdManagerModal, setShowAdManagerModal] = useState(null)

    // useEffect(() => {
    //   // Load the Google Identity Services library dynamically
    //   const script = document.createElement('script')
    //   script.src = 'https://accounts.google.com/gsi/client'
    //   script.async = true
    //   script.onload = initializeGoogleSignIn
    //   document.body.appendChild(script)
    // }, [])

    useEffect(() => {
      if (socialAccounts) {
        setShowAdManagerModal(true)
      }
    }, [socialAccounts])

    // const initializeGoogleSignIn = () => {
    //   if (window.google) {
    //     const client = window.google.accounts.oauth2.initCodeClient({
    //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //       scope:
    //         'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
    //       ux_mode: 'redirect',
    //       redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    //       state: 'google_onboarding'
    //     })
    //     setGoogleClient(client)
    //   } else {
    //     console.error('Google Identity Services library not loaded')
    //   }
    // }

    const clickHandler = () => {
      ref.current.setAttribute('data-clicked', 'true')
      setSelectedSection('businessType')
      scrollHandler('businessType')
    }

    const solutionNotListedHandler = () => {
      setSolutionExisting(false)
    }
    const handleOnChange = () => {}

    const addButtonHandler = (solutionData, index) => {
      const revSolutionData = JSON.parse(JSON.stringify(newSolutionsData))
      revSolutionData[index].status = true
      setNewSolutionsData(revSolutionData)
    }

    const submitNewSolutionHandler = index => {
      const revSolutionData = JSON.parse(JSON.stringify(newSolutionsData))
      revSolutionData[index].isEditable = !revSolutionData[index].isEditable
      setNewSolutionsData(revSolutionData)
    }

    const oauthSignIn = async () => {
      try {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
        const scope =
          'https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email'

        const redirectUrl = process.env.REACT_APP_REDIRECT_URI
        const state = 'google_onboarding'
        const googleAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUrl}&response_type=code&scope=${scope}&state=${state}`
        window.open(googleAuthURL)
        // googleClient.requestCode()
      } catch (err) {
        console.log(err)
      }
    }

    const handleLinkedOauth = async () => {
      try {
        const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID
        const LINKEDIN_CALLBACK_URL = process.env.REACT_APP_REDIRECT_URI

        const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
          LINKEDIN_CALLBACK_URL
        )}&scope=r_ads,r_organization_admin,r_organization_social,rw_ads,rw_organization_admin,w_member_social,w_organization_social&state=linkedin_onboarding`
        window.open(authUrl, '_blank')
      } catch (err) {
        console.log(err)
      }
    }

    const handleSocialLogin = async option => {
      if (
        Array.isArray(option) &&
        option.some(({ name }) => name == 'Facebook')
      ) {
        setConnectingSource('Facebook')
        let isLoggedIn = false
        const facebookLoginRes = await new Promise((resolve, reject) => {
          window.FB.getLoginStatus(function (response) {
            resolve(response)
          })
        })
        const { status } = facebookLoginRes || {}
        if (status == 'connected') {
          const { authResponse: { accessToken } = {} } = facebookLoginRes || {}
          isLoggedIn = true
          setFacebookAccessToken(accessToken)
        }
        if (status == 'unknown' || status == 'not_authorized') {
          const { success, access_token: accessToken } = await new Promise(
            (resolve, reject) => {
              window.FB.login(
                function (response) {
                  if (response.authResponse) {
                    const accessToken = response.authResponse.accessToken
                    const message = {
                      success: true,
                      access_token: accessToken
                    }
                    resolve(message)
                  } else {
                    console.log(
                      'User cancelled login or did not fully authorize.'
                    )
                  }
                },
                {
                  scope: fb_permissions.join(',')
                }
              )
            }
          )
          if (success) {
            isLoggedIn = true
            setFacebookAccessToken(accessToken)
          }
        }
        if (isLoggedIn) {
          try {
            const {
              adaccounts: { data }
            } = await new Promise((resolve, reject) => {
              window.FB.api(
                '/me',
                'GET',
                { fields: 'id,name,adaccounts{name,id,account_id}' },
                function (response) {
                  const { error: { message } = {} } = response || {}
                  if (!message) {
                    console.log(response)
                    resolve(response)
                  } else {
                    reject(message)
                  }
                }
              )
            })

            setModalViewFlag(true)
            setAdAccounts(data)
          } catch (error) {
            console.log(error)
          }
        }
      } else if (
        Array.isArray(option) &&
        option.some(({ name }) => name == 'Google')
      ) {
        oauthSignIn()
      } else if (!Array.isArray(option) && option.name === 'LinkedIn') {
        handleLinkedOauth()
      }
    }

    const handleSubmitFbAccounts = () => {
      setIsLoading(true)
      const data = {
        fb: {
          app_id: process.env.REACT_APP_FACEBOOK_APP_ID,
          fb_exchange_token: facebookAccessToken,
          account_id: selectedUserAdAccounts.map(({ account_id }) => account_id)
        }
      }
      dispatch(updateUserFbAdAccounts(data)).then(({ data, status }) => {
        setIsLoading(false)
        if (status === 200) {
          setShowSuccessOption(true)
        }
      })
    }

    const handleAdManagerModalClose = () => setShowAdManagerModal(false)

    return (
      <section
        id='socialAndAnalytics'
        ref={ref}
        data-clicked={false}
        css={socialAndAnalyticsSection}
      >
        <SideDrawer
          section={'socialAndAnalytics'}
          selectedSection={selectedSection}
        />
        <div
          css={socialAndAnalyticsContent}
          data-selected={selectedSection === 'socialAndAnalytics'}
        >
          <span>Now let's connect the rest of your tech stack</span>
          <span>Select your current solutions</span>
          <span>
            (We'll pull in your historical data and enable new methods of
            omichannel targeting with a seamless user experience.)
          </span>
          <div css={adsOptions}>
            {adsOptionsData.map(option => {
              if (Array.isArray(option) && option.length === 2) {
                return (
                  <div
                    key={option[0].id}
                    css={adsOptionBox(option.length)}
                    onClick={() => handleSocialLogin(option)}
                  >
                    <img alt={option[0].name} src={option[0].source} />
                    <img alt={option[1].name} src={option[1].source} />
                    <div className='optionDescription'>
                      <span className='textStyle'>
                        {option[0].name} | {option[1].name}
                      </span>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div
                    key={option.id}
                    css={adsOptionBox()}
                    onClick={() => handleSocialLogin(option)}
                  >
                    <img alt={option.name} src={option.source} />
                    <div className='optionDescription'>
                      <span className='textStyle'>{option.name}</span>
                    </div>
                  </div>
                )
              }
            })}
          </div>
          <div css={emailOptions}>
            {emailOptionsData.map(option => {
              return (
                <div key={option.id} css={emailOptionBox}>
                  <img alt={option.name} src={option.source} />
                  <div className='optionDescription'>
                    <span className='textStyle'>{option.name}</span>
                  </div>
                </div>
              )
            })}
          </div>
          <div css={analyticsOptions}>
            {analyticsOptionsData.map(option => {
              return (
                <div key={option.id} css={analyticsOptionBox}>
                  <img alt={option.name} src={option.source} />
                  <div className='optionDescription'>
                    <span className='textStyle'>{option.name}</span>
                  </div>
                </div>
              )
            })}
          </div>
          {modalViewFlag && (
            <FbAdAccountPopUp
              isLoading={isLoading}
              showSuccessOption={showSuccessOption}
              adAccounts={adAccounts}
              selectedUserAdAccounts={selectedUserAdAccounts}
              setSelectedUserAdAccounts={setSelectedUserAdAccounts}
              onClose={value => setModalViewFlag(value)}
              setShowSuccessOption={setShowSuccessOption}
              handleSubmitFbAccounts={handleSubmitFbAccounts}
            />
          )}
          {showAdManagerModal ? (
            <AdManagerModal
              onClose={handleAdManagerModalClose}
              socialAccounts={socialAccounts}
            />
          ) : null}
          {solutionExisting && (
            <>
              <button
                css={solutionNotListedButton}
                onClick={solutionNotListedHandler}
              >
                My solution is not listed
              </button>
              <button
                onClick={clickHandler}
                css={allSetButton(solutionExisting)}
              >
                I'm all set. Let's keep going...
              </button>
            </>
          )}
          {!solutionExisting && (
            <div css={solutionNotExistingContainer}>
              <button css={solutionNotListedButton}>
                We'll add it and be in touch shortly...
              </button>
              <div css={addSolutionContainer}>
                {newSolutionsData.map((solutionData, index) => {
                  return solutionData.status ? (
                    <div key={solutionData.id} css={addSolutionOptionContainer}>
                      <div css={addSolutionOption}>
                        <input
                          css={inputStyle}
                          onChange={({ target: { value } = {} }) =>
                            handleOnChange(value)
                          }
                          disabled={!solutionData.isEditable}
                          placeholder={'Company & Product Name'}
                        />
                        <button
                          className='submitButton'
                          onClick={() => submitNewSolutionHandler(index)}
                        >
                          {solutionData.isEditable ? 'Submit' : 'Edit'}
                        </button>
                      </div>
                      {!solutionData.isEditable && (
                        <span className='submissionStatus'>Submitted!!</span>
                      )}
                    </div>
                  ) : (
                    <div
                      key={solutionData.id}
                      css={addSolutionButton}
                      onClick={() => addButtonHandler(solutionData, index)}
                    >
                      <span className='plusIcon'>+</span>
                    </div>
                  )
                })}
                <button
                  onClick={clickHandler}
                  css={allSetButton(solutionExisting)}
                >
                  I'm all set. Let's keep going...
                </button>
              </div>
            </div>
          )}
        </div>
      </section>
    )
  }
)

export default SocialAndAnalyticsSection
