import { validate as uuidValidate } from 'uuid'

const mockAdsDataForDatasource = {
  name: '',
  objective: '',
  ad_text: '',
  headline: '',
  news_feed_link_description: '',
  display_link: '',
  call_to_action: '',
  channel_name: '',
  segment_ids: [],
  audience_ids: [],
  url: ''
}

const mockAdsDataFromDatasourceMapping = {
  id: '',
  source: '',
  url: '',
  isEditable: false,
  createdFromSegments: [],
  status: 'Under Review',
  adName: '',
  objective: '',
  text: '',
  headline: '',
  newsFeedLinkDescription: '',
  displayLink: '',
  callToAction: '',
  audienceData: []
}

export const mapDataForDatasource = (ads, campaignDetails, productId) => {
  const campaignData = {
    campaign_name: campaignDetails.campaignName,
    product_id: productId,
    channel_name: 'facebook',
    is_draft: campaignDetails.isDraft,
    ad_text: campaignDetails.text,
    headline: campaignDetails.headline,
    news_feed_link_description: campaignDetails.newsFeedLinkDescription,
    display_link: campaignDetails.displayLink,
    call_to_action: campaignDetails.callToAction,
    channel_budget: campaignDetails.budget,
    objective: campaignDetails.objective,
    budget: {
      facebook_budget: campaignDetails.facebookBudget,
      instagram_budget: campaignDetails.instagramBudget,
      google_budget: campaignDetails.googleBudget,
      linkedin_budget: campaignDetails.linkedinBudget,
      spread_budget_across_channels: campaignDetails.spreadBudgetAcrossChannels
    },
    start_date: campaignDetails.startDate,
    end_date: campaignDetails.endDate,
    ads: []
  }
  if (campaignDetails.id) {
    campaignData.id = campaignDetails.id
  }
  Object.keys(ads).forEach(segmentId => {
    ads[segmentId].forEach(ad => {
      if (
        ad &&
        Object.keys(ad).length &&
        campaignData.ads.findIndex(({ tempId }) => tempId === ad.id) == -1
      ) {
        const adsData = JSON.parse(JSON.stringify(mockAdsDataForDatasource))
        if (ad.id && !uuidValidate(ad.id)) {
          adsData.id = ad.id
        } else {
          adsData.tempId = ad.id
        }
        adsData.url = ad.url
        adsData.square_image = ad.square_image
        adsData.landscape_image = ad.landscape_image
        adsData.segment_ids = ad.createdFromSegments
        adsData.audience_ids = ad.audienceData
          .filter(({ isSelected }) => {
            if (isSelected) return true
            return false
          })
          .map(audience => `${audience.id}`)
        adsData.channel_name = ad.source
        adsData.name = ad.adName
        adsData.channel_budget = campaignDetails.budget
        adsData.objective = ad.objective
        adsData.row_index = ad.rowIndex
        adsData.ad_text = campaignDetails.text
        adsData.headline = campaignDetails.headline
        adsData.start_date = campaignDetails.startDate
        adsData.end_date = campaignDetails.endDate
        adsData.news_feed_link_description =
          campaignDetails.newsFeedLinkDescription
        adsData.display_link = campaignDetails.displayLink
        adsData.call_to_action = campaignDetails.callToAction
        adsData.objective = campaignDetails.objective
        campaignData.ads.push(adsData)
      }
    })
  })
  return campaignData
}

export const mapDataFromDataSource = (campaignDetails, productId) => {
  return campaignDetails.map(campaignDetail => {
    const campaignData = {
      id: campaignDetail.id,
      channel_name: 'facebook',
      productId: campaignDetail.product_id || productId,
      campaignName: campaignDetail.name,
      isDraft: campaignDetail.is_draft,
      text: campaignDetail.ad_text,
      headline: campaignDetail.headline,
      newsFeedLinkDescription: campaignDetail.news_feed_link_description,
      displayLink: campaignDetail.display_link,
      callToAction: campaignDetail.call_to_action,
      budget: campaignDetail.channel_budget,
      facebookBudget: campaignDetail.budget.facebook_budget,
      instagramBudget: campaignDetail.budget.facebook_budget,
      googleBudget: campaignDetail.budget.facebook_budget,
      spreadBudgetAcrossChannels:
        campaignDetail.budget.spread_budget_across_channels,
      startDate: campaignDetail.start_date,
      endDate: campaignDetail.end_date,
      matchedRecordsTotal: campaignDetail.matched_records_total || 0
    }

    const adsCampaignData = {}
    campaignDetail.segments.forEach(segment => {
      if (segment.status === 'active') {
        adsCampaignData[segment.id] = segment.ads.map(ad => {
          const adsData = JSON.parse(
            JSON.stringify(mockAdsDataFromDatasourceMapping)
          )
          adsData.id = ad.id
          adsData.source = ad.channel_name
          adsData.url = ad.media_string
          adsData.square_image = ad.square_image
          adsData.landscape_image = ad.landscape_image
          adsData.start_date = campaignDetail.start_date
          adsData.end_date = campaignDetail.end_date
          adsData.createdFromSegments = ad.segment_ids.map(id => `${id}`)
          adsData.adName = ad.name
          adsData.objective = ad.objective
          adsData.text = ad.ad_text
          adsData.channel_budget = campaignDetail.channel_budget
          adsData.headline = ad.headline
          adsData.newsFeedLinkDescription = ad.news_feed_link_description
          adsData.displayLink = ad.display_link
          adsData.callToAction = ad.call_to_action
          adsData.rowIndex = ad.row_index
          adsData.audienceData = ad.audience.map(audience => {
            return {
              id: audience.id,
              audienceName: audience.audience_name,
              audienceType: audience.audience_type,
              status: audience.status,
              source: audience.source,
              isSelected: audience.selected,
              matchedRecordsTotal: audience.matched_records_total || 0
            }
          })
          return adsData
        })
        adsCampaignData[segment.id].push({})
      } else if (segment.status === 'inactive') {
        adsCampaignData[segment.id] = [null]
      }
    })
    return { campaignData, adsCampaignData }
  })
}

export const mapDataForDatasourceForCopyOperation = (
  ads,
  campaignDetails,
  productId
) => {
  const campaignData = {
    campaign_name: `Copy of ${campaignDetails.campaignName}`,
    product_id: campaignDetails.productId || productId,
    is_draft: campaignDetails.isDraft,
    ad_text: campaignDetails.text,
    headline: campaignDetails.headline,
    news_feed_link_description: campaignDetails.newsFeedLinkDescription,
    display_link: campaignDetails.displayLink,
    call_to_action: campaignDetails.callToAction,
    channel_budget: campaignDetails.budget,
    budget: {
      facebook_budget: campaignDetails.facebookBudget,
      instagram_budget: campaignDetails.instagramBudget,
      google_budget: campaignDetails.googleBudget,
      linkedin_budget: campaignDetails.linkedinBudget,
      spread_budget_across_channels: campaignDetails.spreadBudgetAcrossChannels
    },
    start_date: campaignDetails.startDate,
    end_date: campaignDetails.endDate,
    ads: []
  }
  Object.keys(ads).forEach(segmentId => {
    ads[segmentId].forEach(ad => {
      if (ad && Object.keys(ad).length) {
        const adsData = JSON.parse(JSON.stringify(mockAdsDataForDatasource))
        adsData.url = ad.url
        adsData.square_image = ad.square_image
        adsData.landscape_image = ad.landscape_image
        adsData.start_date = campaignDetails.startDate
        adsData.end_date = campaignDetails.endDate
        adsData.segment_ids = ad.createdFromSegments
        adsData.row_index = ad.rowIndex
        adsData.audience_ids = ad.audienceData
          .filter(({ isSelected }) => {
            if (isSelected) return true
            return false
          })
          .map(audience => `${audience.id}`)
        adsData.channel_name = ad.source
        adsData.channel_budget = campaignDetails.budget
        adsData.name = ad.adName
        adsData.ad_text = ad.text
        adsData.headline = ad.headline
        adsData.news_feed_link_description = ad.newsFeedLinkDescription
        adsData.display_link = ad.displayLink
        adsData.call_to_action = ad.callToAction
        campaignData.ads.push(adsData)
      }
    })
  })
  return campaignData
}
